export default {
  address: {
    contactNumberLength: 10, // include start with zero or not
    contactNumberMinLength: 11,
    contactNumberStartWithZero: true,
    countryCode: '+66',
    emailRequired: false,
    hidePin: false,
    hideCountry: false,
    fixCountry: 'TH',
    pinCodeRequired: true,
    pinCodeLength: 5,
    pinCodeDigitOnly: true,
    addressLine2Mandatory: true,
  },
  allowInsufficientPoint: false,
  allowPaymentEvoucher: false,
  app: 'panasonicecmshop',
  applyBgColorFromDjango: false,
  applyLogoFromDjango: false,
  appName: 'panasonicecmshop',
  contactInfo: {
    en: '',
    th: '',
  },
  contactEmail: 'ml_pewsth_support@th.panasonic.com',
  contactNumber: '02-231-3683-7 ext. 204',
  customFeeds: true,
  customRewardCategory: true,
  defaultCurrency: 'HKD',
  defaultLanguage: 'th',
  defaultUserIcon: 'assets/img/user.png',
  disableRegisterBtn: true,
  editFormDisabled: true,
  enableNomination: false,
  enforceDefaultLanguage: true,
  expiryPoint: true,
  fallbackRewardIcon: 'assets/img/fallback-rewards.png',
  freshchat: {
    isShow: false,
    pages: ''
  },
  forgotPassword: {
    emailRequired: false,
    emailPatternRequired: false,
    mobileNumberRequired: true,
    employeeIdRequired: true,
  },
  forgotPasswordLink: false,
  footer: {
    logoLink: '',
    privacyLink: '',
    tncLink: '',
    userGuide: '',
    showContactUS: false,
    showStaticContactUS: true,
    showResource: false,
    showAbout: false,
  },
  header: {
    dynamicBg: false,
    dynamicLogo: true,
    firstLogo: true,
    secondLogo: '',
    pointDetail: false,
    notification: false,
    showDepartmentInProfile: true,
  },
  hideSFStoreSFLocker: true,
  home: {
    announcement: false,
    event: false,
    greeting: false,
    featuredRewards: false,
    topRewards: true,
    recommendation: false,
  },
  introIcon: 'assets/img/panasonic.png',
  languageAvailable: ['en', 'th'],
  languageLabel: {
    en: 'English',
    th: 'แบบไทย',
  },
  leftNavigate: null,
  loginReadMore: false,
  loginTermsCondition: false,
  modules: {
    home: true,
    appreciate: false,
    feeds: false,
    rewards: true,
    myWallet: false,
    calendar: false,
    settings: false,
    pointsHistory: true,
    cart: false,
    receipts: false,
    leaderboard: false,
  },
  panel: {
    link: ['/profile/edit'],
    support: false,
    dealerManagement: true,
  },
  profileEdit: {
    editFormDisabled: true,
    navigateToHome: false,
    departmentName: true,
    taxId: true,
    taxPercentage: true,
    registrationDate: true,
  },
  rating: false,
  recognitionRecommendation: false,
  registerNewAccount: false,
  registrationCompletedImg: 'assets/img/key.png',
  rewardDefaultSort: 'lh',
  rewardDetailBackRoute: ['/home'],
  rewards: {
    detailQty: 1,
    seeAll: {
      subcategory: 'drawer',
    },
    filters: {
      points: true,
      pointsCollapsible: false,
      rating: false,
    },
    sorting: true,
    title: 'common.reward.n',
  },
  showActiveDotInBottomMenu: false,
  registerWithEmployeeId: false,
  registerWithLineId: true,
  showDepartmentName: true,
  showEmployeeId: true,
  showExpiringPoints: false,
  showSpecialDeal: true,
  showUsername: false,
  showLanguageChangeIcon: false,
  showBackButton: false,
  showPageSize: false,
  showPointsOnHome: {
    available: false,
    earned: false,
    redeemed: false,
  },
  showRememberMe: true,
  showRegistrationPointsPage: true,
  sideCarousel: [],
  signUpCode: 'cGFuYXNvbmljZWNtc2hvcA==',
  topMenu: true,
};
