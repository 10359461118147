export default {
  header: {
    templateUrl: './top.header.component.html',
    styleUrls: ['./top.header.component.scss'],
  },
  home: {
    templateUrl: './rewards.home.component.html',
    styleUrls: ['./rewards.home.component.scss'],
  },
  editProfile: {
    templateUrl: './panasonicecmshop.edit.component.html',
  },
  inputForm: {
    templateUrl: './panasonicecmshop-input-form.component.html',
  },
  loginCarousel: {
    templateUrl: './full-page.side-carousel.component.html',
    styleUrls: ['./full-page.side-carousel.component.scss'],
  },
  authentication: {
    templateUrl: './panasonicecmshop.authentication.component.html',
  },
  authentication_registration: {
    templateUrl: './panasonicecmshop.registration.component.html',
    styleUrls: ['./panasonicecmshop.registration.component.scss'],
  },
  authentication_registrationCompleted: {
    templateUrl: './panasonicecmshop.registration-completed.component.html',
    styleUrls: ['./panasonicecmshop.registration-completed.component.scss'],
  },
  authentication_verifyOtp: {
    templateUrl: './panasonicecmshop.verify-otp.component.html',
    styleUrls: ['./panasonicecmshop.verify-otp.component.scss'],
  },
  authentication_login: {
    templateUrl: './panasonicecmshop.login.component.html',
    styleUrls: ['./panasonicecmshop.login.component.scss'],
  },
  authentication_forgotPassword: {
    templateUrl: './panasonicecmshop.forgot-password.component.html',
    styleUrls: ['./panasonicecmshop.forgot-password.component.scss'],
  },
};
